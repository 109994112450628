import { EngagementProvider } from "../contexts/EngagementContext";
import PageProvider from "../contexts/PageContext";
import CourseLandingPage from "./CourseLandingPage/CourseLandingPage";

export function Intro() {
    return (
        <PageProvider
            pageType="Course - Landing Page"
            pageName="PT - Intro to Regulatory Affairs"
        >
            <EngagementProvider>
                <CourseLandingPage />
            </EngagementProvider>
        </PageProvider>
    );
}
