import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { theme } from "./themes";
import GlobalProvider from "./contexts/GlobalContext";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <React.StrictMode>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <GlobalProvider>
                        <App />
                    </GlobalProvider>
                </ThemeProvider>
            </BrowserRouter>
        </React.StrictMode>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// const handlePerfEntry = (metric: any) => {
//   console.log(metric);
// };

// reportWebVitals(handlePerfEntry);
