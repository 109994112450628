import { useState, useEffect } from "react";
import Course from "../shared/classes/course";
import { getDeviceProps } from "../shared/utils/deviceType";
import { Events } from "../shared/types";
// import { useAnalytics } from "./useAnalytics";

export function useCourseData() {
    const [course, setCourse] = useState<Course | undefined>();
    //   const analytics = useAnalytics();

    useEffect(() => {
        // const handleLoad = () => {
        //   const navigationTiming = performance.getEntriesByType(
        //     "navigation"
        //   )[0] as any;
        //   const pageLoadTime = Math.floor(
        //     navigationTiming?.domContentLoadedEventEnd
        //   );

        //   const deviceProps = getDeviceProps();
        //   analytics.setUserProperties(deviceProps);

        //   analytics.trackEvent(Events.PAGE_LOADED, {
        //     pageLoadTimeMS: pageLoadTime,
        //   });
        // };

        // window.addEventListener("load", handleLoad);

        async function fetchCourse() {
            // const course_ = data as Course;
            const course_ = await Course.fromJSON("courses/intro.json");
            setCourse(course_);
        }

        fetchCourse();
        // return () => {
        //   window.removeEventListener("load", handleLoad);
        // };
    }, []);

    return { course };
}
