import React from "react";
import { useParams } from "react-router-dom";

const PDFViewer = () => {
    const { certID } = useParams(); // Get the certID parameter from the route

    let pdfUrl =
        "https://ebsccs38dah6rveg.public.blob.vercel-storage.com/" + certID;

    if (!pdfUrl.endsWith(".pdf")) {
        pdfUrl += ".pdf";
    }

    const containerStyle = {
        width: "100%",
        height: "99.4vh",
        margin: 0,
        padding: 0,
    };

    const embedStyle = {
        width: "100%",
        height: "100%",
        border: "none",
    };

    return (
        <div style={containerStyle}>
            <embed src={pdfUrl} style={embedStyle} type="application/pdf" />
        </div>
    );
};

export default PDFViewer;
